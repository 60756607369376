const title = 'Mortgage Made Easy';
const partnerText = `Ready to realize the dream of homeownership? Our team of industry-leading mortgage specialists are here to help. As your trusted partners, we are committed to providing you with a competitive advantage like none other: speed, experience, and unprecedented savings.`;

const kellerAdvantageLang = [
  {
    title: '98',
    titleSpecial: '%',
    lineOne: 'DEALS CLOSED',
    lineTwo: 'ON-TIME OR EARLY',
  },
  {
    title: '4.8',
    titleSpecial: '/5',
    lineOne: 'AVG. CUSTOMER',
    lineTwo: 'SATISFACTION SCORES',
  },
];

export default { kellerAdvantageLang, title, partnerText };
