import React from 'react';
import SecondaryButton from 'components/commons/SecondaryButton/SecondaryButton';
import ApplyNowButton from 'components/landingPage/ApplyNowButton/ApplyNowButton';
import { GaEvent, GaPage } from 'src/common/types';
import sendGaEvent from 'src/utils/gtag';
import styles from './call-to-action.module.scss';

interface ContentCardProps {
  buttons?: { text: string; gaEvent: GaEvent; href: string }[];
  cardText: string;
  cardTitle: string;
  buttonType?: string;
  page: GaPage;
}
const ContentCard = ({
  buttons = [],
  cardText,
  cardTitle,
  buttonType = 'secondary',
  page,
}: ContentCardProps) => {
  return (
    <div className={styles.contentCard}>
      <h2 className={styles.cardTitle}>{cardTitle}</h2>
      <p className={styles.cardText}>{cardText}</p>
      <div className={styles.buttonContainer}>
        {buttons?.map(button => {
          if (buttonType === 'primary') {
            return (
              <ApplyNowButton
                specialWidth="small"
                key={`call-to-action ${button.text}`}
                gaEvent={button.gaEvent}
                gaPage={page}
              >
                {button.text}
              </ApplyNowButton>
            );
          }
          return (
            <SecondaryButton
              linkTo={button.href}
              key={`call-to-action ${button.text}`}
              handleOnClick={() => sendGaEvent(button.gaEvent, page)}
            >
              {button.text}
            </SecondaryButton>
          );
        })}
      </div>
    </div>
  );
};

export default ContentCard;
