import React from 'react';
import Container from 'components/commons/Container/Container';
import ApplyNowButton from 'components/landingPage/ApplyNowButton/ApplyNowButton';
import FamilyCelebraring from 'src/../public/img/landing-page/keller-advantage/family-celebrating.jpg';
import { GaPage } from 'src/common/types';
import lang from 'src/data/lang/keller-advantage.lang';
import styles from './keller-advantage.module.scss';

const KellerAdvantage = ({ page = 'home' }: { page?: GaPage }) => {
  const { title, partnerText } = lang;

  return (
    <Container horizontalSpacing="small">
      <>
        <div className={styles.kellerAdvantage}>
          <div className={styles.headline}>
            <h4 className={styles.title}>{title}</h4>
            <p className={styles.partnerText}>{partnerText}</p>
            <div className={styles.buttonContainer}>
              <ApplyNowButton
                look="applyNow"
                gaEvent="clickedApplyNowKeller"
                gaPage={page}
              >
                Apply Now!
              </ApplyNowButton>
            </div>
          </div>
          <div className={styles.contentCardContainer}>
            <div className={styles.doorImage}>
              <img
                src={FamilyCelebraring}
                width={880}
                height={785}
                alt="Family Celebrating"
              />
            </div>
          </div>
        </div>
      </>
    </Container>
  );
};

KellerAdvantage.defaultProps = {
  page: 'home',
};

export default KellerAdvantage;
