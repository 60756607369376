import { GaEvent } from 'src/common/types';

export const callToActionLang = {
  helpLang: {
    title: 'Here to help',
    text:
      'In addition to our tools and resources, our Customer Support team is on hand to answer any questions you have.',
    buttons: {
      support: 'FAQ',
      contact: 'Contact us',
    },
  },
  applyLang: {
    title: 'Apply now',
    text:
      'Our step-by-step guide will bring you through the process. It’s fast, easy, and you can start saving thousands today.',
    buttons: {
      apply: 'Apply Now!',
    },
  },
};

const { helpLang, applyLang } = callToActionLang;

type CtaButton = { text: string; href: string; gaEvent: GaEvent };

const buttons: {
  helpButtons: CtaButton[];
  applyButtons: CtaButton[];
} = {
  helpButtons: [
    {
      text: helpLang.buttons.support,
      href: '/faq',
      gaEvent: 'clickedFaq',
    },
    {
      text: helpLang.buttons.contact,
      href: '/faq#contactus',
      gaEvent: 'clickedContactUs',
    },
  ],
  applyButtons: [
    {
      text: applyLang.buttons.apply,
      href: '/mortgage-application/get-agent-info',
      gaEvent: 'clickedApplyNowContact',
    },
  ],
};

export default buttons;
