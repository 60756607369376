import React from 'react';
import Container from 'components/commons/Container/Container';
import { GaPage } from 'src/common/types';
import buttons, { callToActionLang } from 'src/data/lang/call-to-action.lang';
import styles from './call-to-action.module.scss';
import ContentCard from './ContentCard';

const CallToAction = ({ page = 'home' }: { page?: GaPage }) => {
  const { helpLang, applyLang } = callToActionLang;
  return (
    <Container>
      <div className={styles.contentCardContainer}>
        <ContentCard
          buttons={buttons.helpButtons}
          cardText={helpLang.text}
          cardTitle={helpLang.title}
          page={page}
        />
        <ContentCard
          buttons={buttons.applyButtons}
          cardText={applyLang.text}
          cardTitle={applyLang.title}
          buttonType="primary"
          page={page}
        />
      </div>
    </Container>
  );
};

export default CallToAction;
