import BidWithConfidence from 'components/landingPage/BidWithConfidence/BidWithConfidence';
import CallToAction from 'components/landingPage/CallToAction/CallToAction';
import KellerAdvantage from 'components/landingPage/KellerAdvantage/KellerAdvantage';
import Layout from 'components/landingPage/Layout/Layout';
import { useEffect } from 'react';
import { useFormMachine } from 'src/context/FormMachineProvider';

export default function Home() {
  const [state, send] = useFormMachine();

  useEffect(() => {
    if (state.context.flowInfo.flowCompleted) {
      send('NAVIGATE_TO_HOME');
    }
  }, [send, state.context.flowInfo.flowCompleted]);

  return process.env.NEXT_PUBLIC_DEPLOY_VARIATION === 'mypreapp' ? (
    <div />
  ) : (
    <Layout>
      <BidWithConfidence />
      <KellerAdvantage />
      <CallToAction />
    </Layout>
  );
}
